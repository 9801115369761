import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../environments/environment';
import { provideServiceWorker } from '@angular/service-worker';
import { ThemeService, ThemeVariantMode } from '@drip/core/theme';
import { ENVIRONMENT, EnvironmentStage } from '@drip/core/environment';
import { IOWEnvironment } from '@app-environments/IOWEnvironment';
import { DataService, FakeApiDataService, WebApiDataService } from '@app-services';
import { BaseDataService } from '@drip/core/data-access';
import { httpAuthInterceptor } from '@app-interceptors';
import * as Sentry from '@sentry/angular';

const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

const initTheme = (themeService: ThemeService) => {
  return () => themeService.init(ThemeVariantMode.Auto);
};

export function DataServiceFactory(http: HttpClient, env: IOWEnvironment) {
  if (env.api.useMockData) {
    return new FakeApiDataService();
  }
  return new WebApiDataService(http, env);
}

export function BaseDataServiceFactory(dataService: DataService) {
  return dataService;
}

const DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        /* EMPTY */
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    provideRouter(appRoutes),
    provideAnimationsAsync('animations'),
    provideHttpClient(withInterceptors([httpAuthInterceptor])),
    importProvidersFrom(TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      defaultLanguage: environment.i18n.defaultLanguageCode
    })),
    { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: APP_INITIALIZER, useFactory: initTheme, deps: [ThemeService], multi: true },
    {
      provide: DataService,
      useFactory: DataServiceFactory,
      deps: [HttpClient, ENVIRONMENT]
    },
    {
      provide: BaseDataService,
      useFactory: BaseDataServiceFactory,
      deps: [DataService]
    },
    provideMomentDateAdapter(DATE_FORMAT),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.stage !== EnvironmentStage.LOCAL,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
};
