import { EnvironmentStage, SemanticVersionString } from '@drip/core/environment';
import packageJson from '../../../../package.json';
import { IOWEnvironment } from './IOWEnvironment';

export const environment: IOWEnvironment = {
  stage: EnvironmentStage.QA,
  version: packageJson.version as SemanticVersionString,
  api: {
    baseUrl: 'https://api.qa.drip-log.com/ow',
    useMockData: false
  },
  sentry: {
    dsn: 'https://a05fef113ce9771511f204775ee806c2@o1151456.ingest.us.sentry.io/4507814357696512'
  },
  map: {
    accessToken: 'pk.eyJ1IjoiZDNlaWYiLCJhIjoiY2prMmFzMHQxMHFkbTNycXFiMWw2YTF6ciJ9.spE1RhuEXF5kzUQaRB8kYg',
    lightModeStyle: 'mapbox://styles/d3eif/ckity6g352da619pfdw4ik4hj?optimize=true',
    darkModeStyle: 'mapbox://styles/d3eif/ck2oq1hyn0dv61cp4eqpe2wi4?optimize=true',
    latitudeOffset: 0.003
  },
  i18n: {
    availableLanguages: [
      { name: 'English', code: 'en' },
      { name: 'Deutsch', code: 'de' }
    ],
    defaultLanguageCode: 'en'
  }
} as const;
