{
  "name": "one-way",
  "version": "1.1.0",
  "license": "MIT",
  "scripts": {
    "start": "npm run serve",
    "serve": "nx run web:serve:local",
    "serve:prod": "nx run web:serve:production",
    "serve:qa": "nx run web:serve:development",
    "build": "nx run web:build",
    "build:dev": "nx run web:build:development",
    "build:prod": "nx run web:build:production",
    "build:affected:dev": "nx affected:build --configuration=development",
    "build:affected:prod": "nx affected:build --configuration=production",
    "build:dev:android": "npm run build:dev",
    "build:prod:android": "npm run build:prod",
    "android:sync": "",
    "lint": "nx run web:lint",
    "lint:all": "nx run-many --all -t lint",
    "lint:affected": "nx affected:lint",
    "test": "nx run web:test",
    "test:all": "nx run-many --all -t test",
    "test:affected": "nx affected:test",
    "e2e": "nx run web-e2e:e2e:production",
    "e2e-headless": "nx run web-e2e:e2e-ci--src/e2e/app.cy.ts",
    "test-storybook:lib": "test-storybook --url http://localhost:4400 -c libs/drip-shared-components/.storybook",
    "test-storybook:app": "test-storybook --url http://localhost:4400 -c apps/web/.storybook"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~17.3.0",
    "@angular/cdk": "~17.3.3",
    "@angular/common": "~17.3.0",
    "@angular/compiler": "~17.3.0",
    "@angular/core": "~17.3.0",
    "@angular/forms": "~17.3.0",
    "@angular/material": "~17.3.3",
    "@angular/platform-browser": "~17.3.0",
    "@angular/platform-browser-dynamic": "~17.3.0",
    "@angular/router": "~17.3.0",
    "@angular/service-worker": "17.3.3",
    "@capacitor/camera": "^6.0.1",
    "@capacitor/core": "^6.1.0",
    "@capacitor/geolocation": "^6.0.0",
    "@ionic/pwa-elements": "^3.3.0",
    "@mapbox/mapbox-gl-language": "^1.0.1",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@nx/angular": "18.2.3",
    "@sentry/angular": "^8.26.0",
    "@tailwindcss/container-queries": "^0.1.1",
    "@types/mapbox-gl": "^3.1.0",
    "mapbox-gl": "^3.5.1",
    "moment": "^2.30.1",
    "ngx-file-drop": "^16.0.0",
    "ngx-image-compress": "^15.1.6",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.3.0",
    "@angular-devkit/core": "~17.3.0",
    "@angular-devkit/schematics": "~17.3.0",
    "@angular-eslint/eslint-plugin": "~17.3.0",
    "@angular-eslint/eslint-plugin-template": "~17.3.0",
    "@angular-eslint/template-parser": "~17.3.0",
    "@angular/cli": "~17.3.0",
    "@angular/compiler-cli": "~17.3.0",
    "@angular/language-service": "~17.3.0",
    "@angular/material-moment-adapter": "^17.3.3",
    "@angular/pwa": "^17.3.0",
    "@capacitor/cli": "^6.1.0",
    "@nx/cypress": "18.2.3",
    "@nx/eslint": "18.2.3",
    "@nx/eslint-plugin": "18.2.3",
    "@nx/jest": "18.2.3",
    "@nx/js": "18.2.3",
    "@nx/storybook": "^18.2.3",
    "@nx/web": "18.2.3",
    "@nx/workspace": "18.2.3",
    "@schematics/angular": "~17.3.0",
    "@storybook/addon-actions": "^8.1.8",
    "@storybook/addon-essentials": "^8.1.8",
    "@storybook/addon-interactions": "^8.1.8",
    "@storybook/angular": "^8.1.8",
    "@storybook/core-server": "^8.1.8",
    "@storybook/manager-api": "^8.1.10",
    "@storybook/test": "^8.1.8",
    "@storybook/test-runner": "^0.18.2",
    "@storybook/theming": "^8.1.10",
    "@swc-node/register": "~1.8.0",
    "@swc/core": "~1.3.85",
    "@swc/helpers": "~0.5.2",
    "@types/jest": "^29.4.0",
    "@types/node": "18.16.9",
    "@typescript-eslint/eslint-plugin": "^7.3.0",
    "@typescript-eslint/parser": "^7.3.0",
    "autoprefixer": "^10.4.0",
    "cypress": "^13.6.6",
    "eslint": "~8.57.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-cypress": "^2.13.4",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-preset-angular": "~14.0.3",
    "jsonc-eslint-parser": "^2.1.0",
    "ng-packagr": "~17.3.0",
    "nx": "18.2.3",
    "postcss": "^8.4.5",
    "postcss-url": "~10.1.3",
    "prettier": "^2.6.2",
    "storybook": "^7.5.3",
    "tailwindcss": "^3.0.2",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.4.2"
  }
}
