import { Route } from '@angular/router';
import { RouteParts } from '@app-enums';
import { authGuard } from '@app-guards';

export const appRoutes: Route[] = [
  {
    path: `${RouteParts.LANDING}/:link`,
    loadComponent: () => import('./modules/landing/pages/landing/landing.component').then(m => m.LandingComponent)
  },
  {
    path: RouteParts.LANDING,
    pathMatch: 'full',
    redirectTo: `${RouteParts.LANDING}/${RouteParts.NO_LINK}`
  },
  {
    path: RouteParts.TRACKING,
    loadComponent: () => import('./modules/tracking/pages/tracking/tracking.component').then(m => m.TrackingComponent),
    canActivate: [authGuard]
  },
  {
    path: `${RouteParts.TRACKING}/:link`,
    loadComponent: () => import('./modules/tracking/pages/tracking/tracking.component').then(m => m.TrackingComponent),
    canActivate: [authGuard]
  },
  {
    path: RouteParts.FINISHED,
    loadComponent: () => import('./modules/finished/pages/finished/finished.component').then(m => m.FinishedComponent),
    canActivate: [authGuard]
  },
  {
    path: `${RouteParts.FINISHED}/:link`,
    loadComponent: () => import('./modules/finished/pages/finished/finished.component').then(m => m.FinishedComponent),
    canActivate: [authGuard]
  },
  {
    path: ':link',
    redirectTo: `${RouteParts.LANDING}/:link`,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: `${RouteParts.LANDING}/${RouteParts.NO_LINK}`,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: `${RouteParts.LANDING}/${RouteParts.NO_LINK}`
  }
];
