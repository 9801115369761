import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@app-services';
import { RouteParts } from '@app-enums';
import { filter, first, firstValueFrom, timeout } from 'rxjs';

export const authGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const link = route.paramMap.get('link');
  if (link && !authService.tokenIsLoading && !authService.isAuthenticated) {
    authService.validateToken(link);
  }
  try {
    await firstValueFrom(authService.tokenIsLoading$.pipe(
      filter(isLoading => !isLoading),
      timeout(5_000),
      first())
    );
  } catch (e) { /* empty */
  }
  if (authService.isAuthenticated) {
    return true;
  }
  router.navigate([RouteParts.LANDING, link ? link : RouteParts.NO_LINK]);
  return false;
};
