import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@app-services';

export const httpAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  if (authService.authToken) {
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authService.authToken
      }
    });
  }
  return next(req);
};
