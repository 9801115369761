import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT } from '@drip/core/environment';
import { IOWEnvironment } from '@app-environments/IOWEnvironment';

@Component({
  standalone: true,
  imports: [
    RouterModule
  ],
  providers: [
    TranslateService
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  constructor(
    private readonly translateService: TranslateService,
    private readonly meta: Meta,
    @Inject(ENVIRONMENT) environment: IOWEnvironment
  ) {
    const browserLang = this.translateService.getBrowserLang();
    if (browserLang && environment.i18n.availableLanguages.some(lang => lang.code === browserLang)) {
      this.translateService.use(browserLang);
    }
    this.meta.addTag({
      name: 'version',
      content: environment.version
    });
  }
}
