import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from '@sentry/angular';
import { environment } from '@app-environments/environment';
import { EnvironmentStage } from '@drip/core/environment';

// Init Sentry
Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.stage === EnvironmentStage.PRODUCTION ? 'production' : 'development',
  enabled: environment.stage !== EnvironmentStage.LOCAL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', environment.api.baseUrl],
  // Session Replay
  replaysSessionSampleRate: environment.stage === EnvironmentStage.PRODUCTION ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Call the element loader before the bootstrapModule/bootstrapApplication call
defineCustomElements(window);
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
